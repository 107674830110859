import Vue from "vue";
import App from "./App.vue";
import "@/assets/style.css";
import router from "./router";
import store from "./store";
import Axios from "axios";
import qs from "qs";
import util from "@/util/util.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 视频
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);
// 提示框
import WotDesign from "wot-design";
import "wot-design/lib/theme-default/index.css";

Vue.use(WotDesign);

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
Vue.prototype.$qs = qs;
Vue.prototype.util = util;
Vue.prototype.HOST = process.env.NODE_ENV === "production" ? "" : "/api";
Vue.use(ElementUI);

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
