import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
import Home from "../views/Home.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "益鸟美居  益鸟维修，房屋维修+装修改造，真自营不外包",
    },
    component: Home,
  },
  {
    path: "/product",
    name: "product",
    meta: {
      title: "益鸟美居-产品中心",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Product.vue"),
  },
  {
    path: "/yicase",
    name: "yicase",
    meta: {
      title: "益鸟美居-益鸟案例",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/yicase.vue"),
  },
  {
    path: "/casedetail",
    name: "casedetail",
    meta: {
      title: "益鸟美居-益鸟案例详情",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/casedetail.vue"),
  },
  {
    path: "/team",
    name: "team",
    meta: {
      title: "益鸟美居-益鸟团队",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/team.vue"),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "益鸟美居-新闻资讯",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
  },
  {
    path: "/newsdetail",
    name: "newsdetail",
    meta: {
      title: "益鸟美居-新闻详情",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsdetail.vue"),
  },
  {
    path: "/aboutus",
    name: "aboutus",
    meta: {
      title: "益鸟美居-关于我们",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutus.vue"),
  },
  {
    path: "/recruit",
    name: "recruit",
    meta: {
      title: "益鸟美居-职位详情",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/recruit.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: yprocess.env.BASE_URL,
  base: "/",
  routes,
});

export default router;
