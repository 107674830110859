<template>
  <div class="header">
    <div class="tou flex_center flex_items">
      <div class="flex_between flex_items touineer f17 ptb10">
        <div>
          <!-- <span>成都</span> -->
          <span class="ml10"
            >益鸟美居 益鸟维修，房屋维修+装修改造，真自营不外包</span
          >
        </div>
        <div class="flex_start flex_items">
          <span class="mr5">24小时咨询热线:</span>
          <img
            :src="imgUrl + 'tu/dianhua.png'"
            alt=""
            class="dianhua "
            mode="aspectFill"
          />
          <span> 4000-987-009</span>
        </div>
      </div>
    </div>
    <div class="flex_center flex_items bgwhite">
      <div class="touineer flex_start flex_items">
        <div class="ptb10 headerlogo">
          <img :src="imgUrl + 'tu/logo1.png'" alt="" class="logo1" />
        </div>
        <div class="flex_start flex_items ml30">
          <div
            v-for="(item, index) in navs"
            :key="index"
            class="f-center nav flex_items mr40"
            :style="{
              background: page == item ? '#007B46' : 'none',
              color: page == item ? 'white' : 'black',
            }"
            @click="tiaobav(item)"
          >
            <span> {{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      page: "",
      navs: [
        "首页",
        "产品中心",
        "益鸟案例",
        "益鸟团队",
        "新闻资讯",
        "关于我们",
      ],
    };
  },
  created() {
    // this.$store.commit("setpage", "首页");
    this.page = this.$store.state.home;
  },
  methods: {
    tiaobav(item) {
      let name2 = "";
      if (item == "首页") {
        name2 = "Home";
      } else if (item == "产品中心") {
        name2 = "product";
      } else if (item == "益鸟案例") {
        name2 = "yicase";
      } else if (item == "益鸟团队") {
        name2 = "team";
      } else if (item == "新闻资讯") {
        name2 = "news";
      } else {
        name2 = "aboutus";
      }
      this.$store.commit("setpage", item);
      this.$router.push({
        name: name2,
        params: {},
      });
    },
  },
  computed: {
    isFollow() {
      return this.$store.state.home; //需要监听的数据
    },
  },
  watch: {
    isFollow() {
      this.page = this.$store.state.home;
    },
  },
};
</script>

<style>
.tou {
  background: black;
  color: white;
  width: 100%;
}
.touineer {
  min-width: 1200px;
}
.dianhua {
  width: 26px;
  height: 26px;
}
.logo1 {
  width: 200px;
}
.headerlogo {
  margin-right: 100px;
}
.nav {
  width: 100px;
  height: 100px;
  line-height: 100px;
  cursor: pointer;
}
.select {
  background: #007b46;
  color: white;
}
</style>
