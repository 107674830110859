<template>
  <div class="jiao ptb10 f17 flex_center flex_items">
    <div class="jiaoneer flex_between flex_items">
      <div class="">
        <div class="logobei pt10 plr20">
          <img
            :src="imgUrl + 'tu/logo.png'"
            alt=""
            class="logo"
            mode="aspectFill"
          />
        </div>
      </div>
      <div class="flex_start flex_items">
        <div class="flex_column flex_items">
          <div class="flex_start flex_items">
            <router-link
              to="/"
              class="f-center f13 footerlink"
              style="color: white"
              >网站首页</router-link
            >
            <router-link
              to="/product"
              class="f-center f13 footerlink"
              style="color: white"
              >产品中心</router-link
            >
            <router-link
              to="/yicase"
              class="f-center f13 footerlink"
              style="color: white"
              >益鸟案例</router-link
            >
            <router-link
              to="/team"
              class="f-center f13 footerlink"
              style="color: white"
              >益鸟团队</router-link
            >
            <router-link
              to="/news"
              class="f-center f13 footerlink"
              style="color: white"
              >新闻资讯</router-link
            >
            <router-link
              to="/aboutus"
              class="f-center f13 footerlink"
              style="color: white"
              >关于我们</router-link
            >
          </div>
          <div
            class="flex_between flex_items"
            style="width: 100%; margin-top: 35px"
          >
            <div class="flex_start flex_items">
              <img
                :src="imgUrl + 'tu/yeweidianhua.png'"
                alt=""
                class="dadianhua mr10"
              />
              <div class="flex_column">
                <p class="f14 f-left">联系电话:</p>
                <p class="f28">4000-987-009</p>
              </div>
            </div>
            <div class="flex_start flex_items">
              <img
                :src="imgUrl + 'tu/yeweidizhi.png'"
                alt=""
                class="dadianhua2 mr10"
              />
              <div class="flex_column">
                <p class="f14 f-left">公司地址:</p>
                <p class="f14 mt5">
                  {{ title }}
                </p>
              </div>
            </div>
          </div>
          <p
            class="f-center"
            style="color: #959595; margin-top: 30px; font-size: 12px"
          >
            <a href="https://beian.miit.gov.cn/" style="text-decoration:none"
              ><span style="color:white">
                Copyright ©2017 蜀ICP备12010478号</span
              ></a
            >
          </p>
        </div>
        <div class="flex_start flex_items ml35">
          <img
            :src="imgUrl + 'tu/gongzhonghao.png'"
            alt=""
            class="erweima mr10"
          />
          <img :src="imgUrl + 'tu/taobao.png'" alt="" class="erweima" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: this.util.imgUrl,
      title: "",
    };
  },
  created() {
    this.title = "四川省成都市金牛区万通金融广场西塔3137-3140";
    // if(window.location.host=="sh.51yiniao.com"){
    //   this.title="上海【闵行店】闵行区水清路1050号";
    // }else{
    //   this.title="四川省成都市金牛区万通金融广场西塔3137-3140";
    // }
  },
  methods: {},
};
</script>

<style>
.jiaoneer {
  min-width: 1200px;
  color: white;
}
.jiao {
  background: black;
  width: 100%;
  height: 180px;
}
.logobei {
  /* background: #06332e; */
  margin-top: -18px;
}
.logo {
  width: 203px;
  /* height: 212px; */
}
.footerlink {
  width: 80px;
  border-right: 1px solid white;
  padding: 0 10px 0 10px;
  font-size: 13px;
  cursor: pointer;
}
.footerlink:last-child {
  border: none;
}
.dadianhua {
  width: 51px;
  height: 52px;
}
.dadianhua2 {
  width: 45px;
  height: 56px;
}
p {
  margin: 0;
}
.erweima {
  width: 120px;
  height: 120px;
}
</style>
